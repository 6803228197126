import {
  faLongArrowAltRight,
  faAngleDown,
  faAngleUp,
  faTimes,
  faChevronRight,
  faSpinner,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';

export const iconTypes = {
  arrowRight: faLongArrowAltRight,
  caretDown: faAngleDown,
  caretUp: faAngleUp,
  close: faTimes,
  chevronRight: faChevronRight,
  chevronLeft: faChevronLeft,
  loading: faSpinner
};