<section id="about-dream-initiative">
    <div class="section-container">
        <div class="content-container container">
            <div class="content col">
                <h1 class="title">
                    About the DREAM Initiative®
                </h1>
                <span class="content">
                    DREAM: Driving Racial Equity in Aesthetic Medicine® is a comprehensive initiative created by Allergan Aesthetics and
                    skin<strong>better</strong> science®, designed to address effects of systemic racism in aesthetic medicine. The Forces of Beauty® 2022 report
                    is the latest component of our program, which aims to further EED&#38;I education within the world of aesthetics and the
                    beauty industry as a whole.
                </span>
                <p class="content">
                    See more about other DREAM Initiatives® 
                    <a href="https://www.prnewswire.com/news-releases/dream-initiative-releases-the-full-spectrum-of-dermatology-a-diverse-and-inclusive-atlas-301383427.html" target="_blank">Atlas</a> 
                    and 
                    <a href="https://www.prnewswire.com/news-releases/allergan-aesthetics-and-skinbetter-science-announce-the-dream-initiative-to-advance-health-equity-and-diversity-in-aesthetics-301129697.html" target="_blank">CARE</a>.
                </p>
            </div>

        </div>
        <div class="image-container col">
            <img class="desktop"
                src="https://media.allergan.com/allerganaesthetics/dreamforequity/dream_women_fade.png" />
            <img class="mobile"
                src="https://media.allergan.com/allerganaesthetics/dreamforequity/dream_women_fade_mobile.png" />
        </div>
    </div>
</section>