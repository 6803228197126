<section id="about">
    <app-section-content>
        <div class="container">
            <div class="col">
                <h1 class="section-title">
                    About Forces of Beauty®
                </h1>
                <p class="section-content">
                    The Forces of Beauty® 2022 report is a study sourced from approximately 4,000 women of various racial and
                    ethnic
                    backgrounds. In
                    addition to valuable data, their voices lend firsthand narratives to the larger conversation around
                    representation in
                    beauty marketing.
                </p>
                <p class="section-content">
                    Our research provides aesthetics practitioners, media, and industry leaders insights to better
                    understand the dynamic between beauty and racial identity for women within the United States.
                    Let's make the
                    world of aesthetics more inclusive and better serve all patients.
                </p>
                <a href="https://media.allergan.com/allerganaesthetics/dreamforequity/reports/UNB157406-DREAM%20Forces%20of%20Beauty%20Report.pdf"
                    target="_blank">
                    <app-btn [style]="buttonStyles.primarySmall">VIEW REPORT</app-btn>
                </a>
            </div>
            <div class="col video-col">
                <app-video 
                    [id]="id"
                    [poster]="poster"
                    [videoId]="videoId"
                >
                </app-video>
            </div>
        </div>
    </app-section-content>
</section>