<div class="footer-container">
    <div class="logo-container">
        <div>
            <img i18n-alt src='https://media.allergan.com/allerganaesthetics/dreamforequity/skinbetter%20logo.png'
                alt='Skinbetter Science' />
        </div>
        <div>
            <img i18n-alt class="abbvie-logo" src='https://media.allergan.com/allerganaesthetics/dreamforequity/abbvie-logo-with-tagline.png'
                alt='Allergan Aesthetics logo' />
        </div>
    </div>
    <div class="info-container">
        <div class="stacked">
            <div class="inline">
                <button
                    style="color: white; text-transform: capitalize; padding-right: 15px; font-family:'normalidad-wide', sans-serif; font-weight: normal;"
                    id="ot-sdk-btn" class="ot-sdk-show-settings cookie-settings">Cookie Settings</button>
                <span i18n>© {{getYear()}} AbbVie. All rights reserved.</span>

            </div>
            <span i18n class="footer-tag">UNB152356-v4 09/22</span>
        </div>

    </div>
</div>